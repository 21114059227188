<template>
    <div class="notes-container">
        <header>
            <div class="back" @click="$emit('close')">
                <img src="/assets/images/NavBackButton.svg" alt="" />
            </div>
            <div class="title">
                Notes
            </div>
        </header>
        <main>
            <div class="notes" v-if="entries.length">
                <template v-for="(item, i) in entries" :key="i">
                    <Note :item="item" />
                </template>
            </div>
            <template v-else>
                <div class="loading">
                    <span class="loader"></span>
                </div>
            </template>
            <div class="create">
                <span v-if="!create" class="create-btn" @click="create = true">
                    + add note
                </span>
                <div v-if="create" class="create-new">
                    <div class="title">Create New Log</div>
                    <div class="value">
                        <input type="number" v-model="newValue">
                        <span>{{data[0].scale}}</span>
                    </div>
                    <textarea v-model="newNote"></textarea>
                    <div class="btns">
                        <span class="back" @click="create = false">back</span>
                        <div class="create-btn submit" @click="submitNote">
                            <template v-if="loaded">submit</template>
                            <template v-else>
                                <div class="loading">
                                    <span class="loader"></span>
                                </div>
                            </template>
                        </div>
                    </div>
                    
                </div>
            </div>
        </main>
    </div>
</template>

<script>
import axiosInstance from '@/services/AxiosTokenInstance'
import Note from './Note.vue'
export default {
    props: {
        data: {
            type: Object,
            required: true,
            default: () => {},
        }
    },

    data() {
        return {
            create: false,
            newNote: '',
            newValue: 0,
            loaded: true,
            entries: [],
            total: 0,
            pages: 0,
            currentPage: 1
        }
    },

    components: {
        Note
    },

    methods: {

        async fetchNotes() {
            const response = await axiosInstance.get(`measurement-logs/${this.data[0].id}`)
            this.entries = response.data.data.data
        },
        
        async submitNote() {
            this.loaded = false

            const toSend = {
                measurement_id: this.data[0].id,
                measurement_value: this.newValue,
                note: this.newNote
            }


            await axiosInstance.post('create-measurement-log', toSend).finally(() => {
                this.loaded = true
                this.create = false
                this.fetchNotes()
            })
        }
    },

    mounted() {
        this.fetchNotes()
    }
}
</script>

<style lang="scss" scoped>
.notes-container {
    position: absolute;
    width: 100%;
    height: 100%;
    background: white;
    z-index: 1;

    header {
        width: 100%;
        min-height: 8vh;
        height: 8vh;
        display: flex;
        position: sticky;
        top: 0;
        justify-content: center;
        align-items: center;
        box-shadow: 0px 1px 8px #142e6e1a;
        z-index: 1;
        background: white;

        .back {
        position: absolute;
        left: 20px;
        cursor: pointer;
        transform: rotate(270deg);
        }

        .title {
        text-transform: uppercase;
        font-weight: 600;
        }
    }

    main {
        height: calc(100% - 8vh);
        overflow: auto;
        padding: 20px;
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        align-items: flex-end;

        .notes {
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 0.5rem;
        }

        .create {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            gap: 0.5rem;
            .create-btn {
                width: fit-content;
                text-transform: capitalize;
                background: var(--primary-color);
                color: white;
                padding: 10px 20px;
                cursor: pointer;
                user-select: none;
                transition: 0.2s;

                &:hover {
                    background: var(--primary-hover);
                }
            }

            .create-new {
                margin-top: 30px;
                position: relative;
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                gap: 0.5rem;
                width: 100%;

                .title {
                    width: 100%;
                    font-weight: 500;
                    color: var(--primary-color);
                }
                .value {
                    width: 100%;
                    position: relative;
                    display: flex;
                    align-items: center;
                    gap: 0.5rem;
                    input {
                        width: 80%;
                        max-width: 5rem;
                        border: solid 1px $grey;
                        padding: 10px;

                        &::-webkit-outer-spin-button,
                        &::-webkit-inner-spin-button {
                            -webkit-appearance: none;
                            margin: 0;
                        }
                    }
                }
                textarea {
                    padding: 10px;
                    width: 100%;
                    height: 15rem;
                    resize: none;
                    border: solid 1px $grey;
                    &:focus {
                        outline: none;
                    }
                }

                .btns {
                    width: 100%;
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                    gap: 0.5rem;

                    .back {
                        padding: 10px 20px;
                        background: $grey;
                        text-transform: capitalize;
                        cursor: pointer;
                        transition: 0.2s;

                        &:hover {
                            background: $grey-hover;
                        }
                    }
                }
            }

        }
    }
}

.loading {
    height: unset;
    background: none;
}

.loader {
    border: 2px solid #f3f3f3;
	border-top: 2px solid var(--primary-color);
	width: 15px;
	height: 15px;
}
</style>